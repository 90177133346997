import { encodeMp3 } from '@/lib/mp3'
import { WebAudioStreamPlayer } from '@/lib/WebApiPlayer'

class AudioStreamPlayer {
    private audioElement: HTMLAudioElement
    private mediaSource: MediaSource
    private sourceBuffer: SourceBuffer | null = null
    private queue: ArrayBuffer[] = []
    // New property to track durations of each chunk
    private _chunkDurations: number[] = []
    isPlaying: boolean = false
    private callbacks: Map<string, Function[]> = new Map()
    // Add a new property to store all chunks for downloading
    private allChunks: ArrayBuffer[] = []

    title: string = ''
    description: string = ''
    currentTime: number = 0
    volume: number = 1

    constructor() {
        this.audioElement = document.createElement('audio')
        this.audioElement.controls = true
        this.mediaSource = new MediaSource()
        this.audioElement.src = URL.createObjectURL(this.mediaSource)

        this.mediaSource.addEventListener(
            'sourceopen',
            this.onSourceOpen.bind(this),
        )

        // Listen for time updates
        this.audioElement.addEventListener('timeupdate', () => {
            this.currentTime = this.audioElement.currentTime
            this.emit('change')
        })
    }

    restart() {
        // Reset playback position to start
        this.audioElement.currentTime = 0
        this.play()
    }

    private onSourceOpen() {
        if (this.mediaSource.readyState !== 'open') return

        const mime = 'audio/mpeg'
        if (MediaSource.isTypeSupported(mime)) {
            this.sourceBuffer = this.mediaSource.addSourceBuffer(mime)
            // Process the queue when the current buffer append finishes.
            this.sourceBuffer.addEventListener(
                'updateend',
                this.processQueue.bind(this),
            )
            this.processQueue()
        } else {
            console.error(
                'MIME type ' + mime + ' is not supported by MediaSource.',
            )
        }
    }

    async addChunk(audioUrl: string) {
        const response = await fetch(audioUrl)
        if (!response.ok) {
            throw new Error(
                `Failed to fetch audio from ${audioUrl.slice(0, 200)} (status: ${response.status})`,
            )
        }
        const arrayBuffer = await response.arrayBuffer()

        // Store the chunk in allChunks for downloading
        this.allChunks.push(arrayBuffer.slice(0))

        // Use an AudioContext to decode the audio data and get its duration.
        const AudioContextConstructor = window.AudioContext
        const audioCtx = new AudioContextConstructor()
        // decodeAudioData operates asynchronously.
        const audioBuffer = await audioCtx.decodeAudioData(arrayBuffer.slice(0))
        const duration = audioBuffer.duration
        this._chunkDurations.push(duration)

        // Add the chunk to the queue, then process it.
        this.queue.push(arrayBuffer)
        this.processQueue()
        this.emit('change')
        if (!this.isPlaying) {
            this.play()
        }
    }

    private processQueue() {
        if (!this.sourceBuffer || this.sourceBuffer.updating) return
        if (this.queue.length > 0) {
            const chunk = this.queue.shift()
            if (chunk) {
                try {
                    this.sourceBuffer.appendBuffer(chunk)
                } catch (e) {
                    console.error('Error appending chunk to sourceBuffer', e)
                    // Requeue the chunk if there's an error.
                    this.queue.unshift(chunk)
                }
            }
        }
    }
    async play(): Promise<void> {
        const playPromise = this.audioElement.play()
        this.isPlaying = true
        this.emit('change')

        // Create promise that resolves when playback ends or is paused
        return new Promise((resolve) => {
            const handleEnd = () => {
                this.isPlaying = false
                this.emit('change')
                resolve()
            }

            this.audioElement.addEventListener('ended', handleEnd, {
                once: true,
            })
            this.audioElement.addEventListener('pause', handleEnd, {
                once: true,
            })
            this.audioElement.addEventListener('cancel', handleEnd, {
                once: true,
            })

            // Also resolve if playback fails
            playPromise.catch(() => {
                this.isPlaying = false
                this.emit('change')
                resolve()
            })
        })
    }

    pause() {
        this.audioElement.pause()
        this.isPlaying = false
        this.emit('change')
    }

    seek(position: number) {
        // position is a percentage from 0-1, convert to actual time
        const targetTime = Math.max(0, position * this.getDuration())
        this.audioElement.currentTime = targetTime
        this.currentTime = targetTime
        this.emit('change')
    }

    updateVolume(volume: number) {
        // Volume should be between 0 and 1.
        this.audioElement.volume = volume
        this.volume = volume
        this.emit('change')
    }

    on(event: string, callback: Function) {
        if (!this.callbacks.has(event)) {
            this.callbacks.set(event, [])
        }
        this.callbacks.get(event)?.push(callback)
    }

    emit(event: string = 'change', data?: any) {
        const eventCallbacks = this.callbacks.get(event)
        if (eventCallbacks) {
            eventCallbacks.forEach((callback) => callback(data))
        }
    }

    getDuration(): number {
        // Return the total duration in seconds
        return this._chunkDurations.reduce((total, dur) => total + dur, 0)
    }

    reset() {
        this.pause()
        if (this.mediaSource.readyState === 'open') {
            this.mediaSource.endOfStream()
        }
        this.queue = []
        this.allChunks = [] // Reset allChunks as well
        // Recreate the MediaSource to reset state.
        this.mediaSource = new MediaSource()
        this.audioElement.src = URL.createObjectURL(this.mediaSource)
        this.mediaSource.addEventListener(
            'sourceopen',
            this.onSourceOpen.bind(this),
        )
        // Reset durations as well.
        this._chunkDurations = []
        this.currentTime = 0
        this.emit('change')
    }

    async download() {
        if (!this.sourceBuffer || this.allChunks.length === 0) {
            return
        }

        // Create a new Blob from all stored audio chunks
        const audioBlob = new Blob(this.allChunks, { type: 'audio/mpeg' })

        // Create download link
        const downloadUrl = URL.createObjectURL(audioBlob)
        const downloadLink = document.createElement('a')
        downloadLink.href = downloadUrl
        downloadLink.download = `${this.title || 'audio'} gesserit.co tts.mp3`

        // Trigger download
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)

        // Clean up
        URL.revokeObjectURL(downloadUrl)
    }

    // Allow external access to the underlying HTMLAudioElement for advanced interactions.
    get element(): HTMLAudioElement {
        return this.audioElement
    }
}

export const globalAudioPlayer =
    typeof window !== 'undefined'
        ? typeof MediaSource !== 'undefined'
            ? new AudioStreamPlayer()
            : new WebAudioStreamPlayer()
        : null
